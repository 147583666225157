import React from "react";
import Img from "gatsby-image";
import { graphql } from "gatsby";
import { css } from "@emotion/core";
import { useIntl } from "gatsby-plugin-intl";

import Layout from "../layout/site-layout";
import Return from "../components/return-button";
import Title from "../components/title";
import SEO from "../components/seo";
import Text from "../components/text";

export const query = graphql`
  query {
    file(name: { eq: "ht_01" }) {
      name
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const InPerson = ({ data }) => {
  const intl = useIntl();

  return (
    <Layout>
      <SEO title="In Person" />
      <Title text="In Person" />
      <Text>
        {intl.formatMessage({ id: "person.text_1" })} (
        <a
          href="https://taennlerdesign.ch"
          rel="noreferrer"
          target="_blank"
          css={css`
            ${textStyles}
          `}
        >
          taennlerdesign.ch
        </a>
        ).
      </Text>
      <Text>{intl.formatMessage({ id: "person.text_2" })}</Text>
      <Img
        fluid={data.file.childImageSharp.fluid}
        css={css`
          max-width: 450px;
          margin: 0 auto;
          margin-top: 40px;
        `}
      />
      <Return />
    </Layout>
  );
};

export default InPerson;

const textStyles = css`
  font-size: 18px;
  line-height: 26px;
  margin-top: 1rem;

  @media screen and (min-width: 768px) {
    font-size: 20px;
    line-height: 28px;
  }

  p {
    margin-bottom: 10px;
  }
`;
